import React, { useState } from 'react';

const GoogleTour = () => {
  const [isIframeActive, setIframeActive] = useState(false);

  const activateIframe = () => {
    setIframeActive(true);
  };

  return (
    <div style={{ width: '100%' }}>
      <h1 className="title2" style={{ textAlign: 'center' }}>Google Tour</h1>
      <div 
        style={{ position: 'relative', width: '100%', height: '450px', cursor: isIframeActive ? 'default' : 'pointer' }} 
        onClick={!isIframeActive ? activateIframe : undefined} 
      >
        {/* Google Tour iframe */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!4v1703008163874!6m8!1m7!1sCAoSLEFGMVFpcE9IV0pnU0FBSDNQeW94eDl6ZEJRamM5YmpiVmVVTjFkTEo2bVZ2!2m2!1d38.49422282352!2d-121.42925913594!3f358.83997480829754!4f-19.52869217995699!5f0.4000000000000002"
          width="100%"
          height="450"
          style={{ border: 0, maxWidth: '100%' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Embedded Google Tour"
        />

        {/* Overlay to prevent interaction until clicked */}
        {!isIframeActive && (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0)', // Transparent overlay
              zIndex: 1
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GoogleTour;
